"use client";

import {
  Button,
  Column,
  Cover,
  Flex,
  Heading,
  Icon,
  RichText,
  Row,
} from "daskapital-ds";
import React, { Suspense } from "react";

import DisplayImage from "@/components/common/DisplayImage/DisplayImage";
import Image from "next/image";
import Link from "next/link";
import NavigationEvents from "@/components/common/NavigationEvents/NavigationEvents";
import { useGlobalContext } from "@/lib/globalContext";

export default function NotFound() {
  const state = useGlobalContext();

  return (
    <>
      <Suspense>
        <NavigationEvents acf={{ page_style: "dark", header_absolute: true }} />
      </Suspense>
      <Cover
        minHeight={"100vh"}
        imageElement={
          <DisplayImage
            alt={"Page 404"}
            url={"/assets/components/404.png"}
            fill={true}
            priority={true}
          />
        }
        overlayOpacity={0}
        overlayColor={"#000"}
      >
        <div>
          <Row className="pt-6 pb-6 text-center">
            <Column md={12}>
              <div>
                <Heading tag="h1" size="h1">
                  {(state?.dict?.page404 as Record<string, string>)?.title}
                </Heading>
                <Link href="/">
                  <Button
                    variant="primary"
                    mode="light"
                    rightIcon={<Icon icon="icon-forward-arrow" />}
                    className="mt-5"
                  >
                    {(state?.dict?.page404 as Record<string, string>)?.button}
                  </Button>
                </Link>
              </div>
            </Column>
          </Row>
        </div>
      </Cover>
    </>
  );
}
